import React, { useState, useEffect } from "react";
import Modal from "new/components/Modal/Modal";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import "./PhoneAuthenticationLogin.scss"
import InputMask from "components/InputMaskCustom";
import { getPhoneAuthenticationMethod, getPhoneAuthenticationCredential} from "helpers/activeAuthMethods";
import firebase from "firebase/app";
import { phoneNumberTest, phoneCodeTest } from "helpers/phoneNumberTest";

const errorConfig = {
  'auth/invalid-verification-code': "O código de verificação é inválido.",
  'auth/expired-verification-code': "O código de verificação expirou. Por favor, solicite um novo.",
  'auth/too-many-requests': "O número de tentativas de login por este método foi excedido. Por favor, tente novamente mais tarde ou utilize um método alternativo.",
  'auth/invalid-phone-number': "O número de telefone inserido é inválido.",
  'auth/user-disabled': "Esta conta de usuário foi desativada.",
  'auth/network-request-failed': "Ocorreu um erro de rede. Verifique sua conexão.",
  'auth/captcha-check-failed': "A verificação reCAPTCHA falhou. Por favor, tente novamente.",
};

export default function PhoneAuthenticationLogin({ showModal, toggleModal }) {
  const [phoneData, setPhoneData] = useState({ phone_number: "", phone_verification_code: "" })
  const [errorMessage, setErrorMessage] = useState("")
  const [confirmationResult, setConfirmationResult] = useState({})
  const [showCode, setShowCode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
        callback: function (response) {
          console.log("reCAPTCHA resolved");
        },
        "expired-callback": function () {
          resetRecaptcha();
        }
      });
    }
  }, []);

  const resetRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setPhoneData({ ...phoneData, [name]: value });
  };

  const handleAuthErrors = (error) => {
    const message = errorConfig[error?.code] || "Ocorreu um erro desconhecido. Por favor, tente novamente.";
    setErrorMessage(message);
    resetRecaptcha();
  };

  const onSignup = async () => {
    setIsLoading(true)
    const appVerifier = window.recaptchaVerifier
    const formatPhone = `+55${phoneData.phone_number}`

    try {
      const result = await getPhoneAuthenticationMethod(formatPhone, appVerifier);
      if (result.message) {
        handleAuthErrors(result);
    } else {
        setConfirmationResult(result);
        setShowCode(true);
      }
    } catch (error) {
      console.log(error);
      handleAuthErrors(error)
    } finally {
      setIsLoading(false);
    }
  };

  const onOTPVerify = async () => {
    setIsLoading(true);
    const verificationId = confirmationResult.verificationId;
    const formatPhoneCode = phoneData.phone_verification_code.replace(/-/g, "");

    try {
      const credential = await getPhoneAuthenticationCredential(verificationId, formatPhoneCode);
      if (credential.message) {
        handleAuthErrors(credential);
      } else {
        setShowCode(false);
        toggleModal(false);
      }
    } catch (error) {
      console.log(error);
      handleAuthErrors(error)
    } finally {
      setIsLoading(false);
      }
  };

  const logout = () => {
    setPhoneData("")
    setErrorMessage("")
    setShowCode(false)
    setIsLoading(false)
    toggleModal(false)
    resetRecaptcha()
  }

  return (
    <Modal
      isOpen={showModal}
      toggleModal={() => toggleModal(false)}
      className="modal-user-login">
      <Box style={{ padding: "2rem" }}>
        <Button
          color="primary"
          onClick={logout}
          className="button-logout-modal-user"
        >
          Cancelar
        </Button>
        <Box className="box-phone-login-title">
          <p>Acessar</p>
          {!showCode ?
            (<span>Insira o número do seu telefone</span>)
            : (<span>Confirme seu número de telefone</span>)}
        </Box>
        {isLoading ? (
          <Box style={{ display: "flex", justifyContent: "center", marginTop: "2em" }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            {errorMessage ? (
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "2em",
                  color: "red",
                  fontSize: 18
                }}>{errorMessage}</Typography>
            ) : (
              <Grid container spacing={3} style={{ marginTop: "2em" }}>
                {!showCode ? (
                  <>
                    <Grid item xs={12}>
                      <span>Número de telefone</span>
                      <InputMask
                        required
                        type="tel"
                        mask="(99)99999-9999"
                        name="phone_number"
                        value={phoneData.phone_number}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-next-email-login"
                      onClick={onSignup}
                      disabled={!phoneNumberTest(phoneData.phone_number)}
                    >
                      Confirmar número de telefone
                    </Button>
                    <span className="title-alert-auth-phone">
                      Se você tocar em “Confirmar número de telefone”, um SMS poderá ser enviado e tarifas de mensagem e de dados serão cobradas.
                    </span>
                  </>
                ) : (
                  <>
                    <div>
                      <p style={{ lineHeight: 0 }}>Insira o código de 6 dígitos enviado para</p>
                      <span>{phoneData.phone_number}</span>
                    </div>
                    <p></p>
                    <Grid item xs={12}>
                      <InputMask
                        required
                        type="tel"
                        mask="999-999"
                        name="phone_verification_code"
                        value={phoneData.phone_verification_code}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button-next-email-login"
                      onClick={onOTPVerify}
                      disabled={!phoneCodeTest(phoneData.phone_verification_code)}
                    >
                      Continuar
                    </Button>
                  </>
                )}
              </Grid>
            )}
          </>
        )
        }
      </Box >
      <div id="recaptcha-container"></div>
    </Modal >
  )
}