import axios from "axios";
import { numberToDecimal, ToDecimal } from "./currency";
import { confirmAlert } from "react-confirm-alert";
import { applyProductFunctions } from "helpers/models/product";
import {
  getDomainFavIcon,
  getDomainMarketColor,
  getDomainName,
  getDomainMetaDescription,
  getDomainKeywords,
} from "config";
import "moment/locale/pt-br";
import moment from "moment-timezone";
import { getEncryptedValue } from "./encryptionHelper";
import { TEST_MODE } from "config";
import { orderRequestFormattedItems } from "./cartHelper";
import { validate } from "cpf-check";
import oAuthRequest from "helpers/registerAxiosOAuthInterceptor";
moment.locale("pt-br");

export function unmask(value) {
  return (value || "")
    .split("(")
    .join("")
    .split(")")
    .join("")
    .split("-")
    .join("")
    .split("/")
    .join("")
    .split(".")
    .join("")
    .split("_")
    .join("")
    .split(" ")
    .join("");
}
export function cepInfo(cep, callBack) {
  //https://api.postmon.com.br/v1/cep/*cep_a_consultar*
  const cepToSend = unmask(cep);
  let url = `https://api.postmon.com.br/v1/cep/${cepToSend}`;
  axios
    .get(url)
    .then((response) => {
      callBack(undefined, response.data);
    })
    .catch((error) => {
      callBack(error);
    });
}

export function formatCpf(value) {
  let unformatted = value || "";
  return (
    unformatted.substring(0, Math.min(3, unformatted.length)) +
    "." +
    unformatted.substring(
      Math.min(3, unformatted.length),
      Math.min(6, unformatted.length)
    ) +
    "." +
    unformatted.substring(
      Math.min(6, unformatted.length),
      Math.min(9, unformatted.length)
    ) +
    "-" +
    unformatted.substring(
      Math.min(9, unformatted.length),
      Math.min(11, unformatted.length)
    )
  );
}

export function defaultHeaders() {
  var token = sessionStorage.getItem("token");
  return {
    "Content-Type": "application/json",
    Authorization: token,
    Accept: "application/json",
  };
}

function getNotificationTitle(color) {
  switch (color) {
    case "success":
      return "Sucesso - ";
    case "info":
      return "Aviso - ";
    case "danger":
      return "Erro - ";
    case "warning":
      return "Atenção - ";
    default:
      return "";
  }
}

export function showNotification(from, align, message, color, delay = 1500) {
  const receivedColor = getCorrectColor(color);
  window.$.notify(
    {
      icon: color === "success" ? "fa fa-clipboard-check" : "fa fa-exclamation",
      message: message,
      title: getNotificationTitle(color),
    },
    {
      allow_dismiss: true,
      delay,
      type: color,
      timer: 1,
      placement: {
        from: from,
        align: align,
      },
      animate: {
        enter: "animated bounceInDown",
        exit: "animated bounceOutUp",
      },
      template:
        `<div style="background-color:${receivedColor}" class="col-lg-6 col-10 text-white card shadow alert alert-{0}" role="alert">` +
        "<button style=\"color: white; font-weight: bold\" type=\"button\" aria-hidden=\"true\" class=\"close\" data-notify=\"dismiss\">×</button>" +
        "<span style=\"margin-right: 10px;font-size: 20px;\" data-notify=\"icon\" class=\"ftext-white alert-inner--icon\"></span>" +
        "<span  class=\"font-weight-bold \"><strong data-notify=\"title\">{1}</strong></span><span class=\"font-weight-regular \" data-notify=\"message\">{2}</span>" +
        "</div>",
    }
  );
}

export function registerShowNotificationFunction() {
  window.showNotification = showNotification;
}

function getCorrectColor(color) {
  const domainColor = getDomainMarketColor()
  switch (color) {
    case "success":
      return "#428E23";
    case "danger":
      return "#BA1200";
    default:
      return domainColor;
  }
}
export function mapErrors(error) {
  var errorData = error.response.data.errors;

  return Object.keys(errorData).reduce((previous, key) => {
    previous[key] = errorData[key];
    return previous;
  }, {});
}

export function mapItemsForm(items) {
  return Object.keys(items);
}
export function maskCNPJ(valor) {
  const cnpjSizeWithoutMask = 14;

  if (valor.length === cnpjSizeWithoutMask) {
    let final = valor.substring(0, 2) + "." + valor.substring(2);
    final = final.substring(0, 6) + "." + final.substring(6);
    final = final.substring(0, 10) + "/" + final.substring(10);
    final = final.substring(0, 15) + "-" + final.substring(15);
    return final;
  }

  return valor;
}
export function maskPhone(valor) {
  valor = unmask(valor);

  if ((valor || "").length > 11) {
    valor = valor.substr(0, valor.length - 1);
  }

  valor = valor.replace(/\D/g, "");
  valor = valor.replace(/^(\d{2})(\d)/g, "($1)$2");
  valor = valor.slice(0, 13).replace(/(\d)(\d{4})$/, "$1-$2");

  return valor;
}

export function testPhone(phone) {
  // restringe a números de telefonia móvel: 11 dígitos, com dígito 9 após o ddd e um dígito de 6 a 9 após o primeiro dígito 9
  const reg = /^\(\d{2}\)9[6-9]\d{3}-\d{4}$/;
  return reg.test(phone);
}

export function makeRequestCreator() {
  var call;
  return function (url, callBack) {
    if (call) {
      call.cancel();
    }
    call = oAuthRequest.CancelToken.source();
    let config = {

      data: {
        a: "a",
      },
      cancelToken: call.token,
    };
    return axios
      .get(url, config)
      .then((response) => {
        callBack(undefined, response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          callBack(undefined, {
            data: [],
          });
        } else {
          callBack(error);
        }
      });
  };
}

export function openUrl(url) {
  let tempLink = document.createElement("a");
  if (url !== "#mercadapp") {
    tempLink.setAttribute("target", "_blank");
  }
  tempLink.setAttribute("href", `${url}`);
  tempLink.setAttribute("style", "display:none;");
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
}

export function translateOrderStatus(status) {
  switch (status) {
    case "canceled":
      return "Cancelado";
    case "waiting":
      return "Aguardando análise";
    case "in_delivery":
      return "Em rota de entrega";
    case "received":
      return "Recebido pela loja";
    case "done":
      return "Pedido entregue";
    case "in_progress":
      return "Em separação";
    default:
      return "";
  }
}
export function parseQueryString(location) {
  var queryHash = {};
  const searchParams = new URLSearchParams(location.search);
  for (let queryPair of searchParams) {
    queryHash[queryPair[0]] = queryPair[1];
  }
  return queryHash;
}

export function insertClearsaleScriptOnDom(a, b, c, d, e, f, g) {
  //Essa parte da função eu criei pra evitar codigo duplicado no DOM, não ta na documentação da clearsale
  if (b.getElementById("clearsaleScript"))
    b.getElementById("clearsaleScript").remove();

  var iframes = document.querySelectorAll("iframe");
  for (var i = 0; i < iframes.length; i++) {
    iframes[i].parentNode.removeChild(iframes[i]);
  }
  //Fim da parte que eu inventei
  a["CsdpObject"] = e;
  a[e] =
    a[e] ||
    function () {
      (a[e].q = a[e].q || []).push(arguments);
    };
  a[e].l = 1 * new Date();
  f = b.createElement(c);

  g = b.getElementsByTagName(c)[0];
  f.async = 1;
  f.src = d;
  f.id = "clearsaleScript";
  g.parentNode.insertBefore(f, g);
}

export function getKiloAmountLabel(amountInKilo) {
  if (amountInKilo >= 1) return `${numberToDecimal(amountInKilo)} Kg`;
  return `${numberToDecimal(amountInKilo * 1000)} g`;
}

export function notification(
  message,
  type,
  locate = "top",
  position = "center"
) {
  showNotification(locate, position, message, type);
}

export function productURL(product, origin) {
  const { market_id, slug, section_id, id } = product;
  const originQuery = origin ? `?origin=${origin}` : "";
  return `/loja/${market_id}/categoria/${section_id}/produto/${slug ? slug : id}${originQuery}`;
}


export function productByBarCodeURL(product, origin) {
  return `/loja/${product.market_id}/produto_cb/${product.cb}${origin ? `?origin=${origin}` : ""
    }`;
}

export function countItensCart(mixesMapped) {
  const valorInicial = 0;
  const response = mixesMapped.reduce((accum, curr) => {
    const soma = accum + curr.amount;
    return soma;
  }, valorInicial);
  return response;
}

export function fixCartForErrors(
  errors,
  cart,
  market,
  removeWholeProduct,
  replaceCartProduct,
  history
) {
  const errorMessages = errors.problems
    .map((problem) => {
      if (problem.action === "replace") {
        replaceCartProduct({
          ...applyProductFunctions([problem.fixed_item])[0],
          amount: cart[problem.fixed_item.id].amount,
        });
      } else {
        if (Array.isArray(problem.item_id_to_remove)) {
          removeWholeProduct(problem.item_id_to_remove[0]);
        } else {
          removeWholeProduct(problem.item_id_to_remove);
        }
      }
      return problem.error_message;
    })
    .join("\n");

  confirmAlert({
    title:
      "Enquanto você fazia suas compras, o supermercado fez algumas alterações:\n\n",
    message: errorMessages,
    buttons: [
      {
        label: "Retornar a loja",
        onClick: () => {
          history.push(`/loja/${market.id}`);
        },
      },
    ],
  });
}

export const updateStyled = () => {
  setTimeout(() => {
    var x = document.getElementsByClassName("firebaseui-title");
    var y = document.getElementsByClassName("firebaseui-phone-tos");
    var z = document.getElementsByClassName("firebaseui-id-submit");
    var a = document.getElementsByClassName("firebaseui-label");
    if (x[0] && y[0] && z[0] && a[0]) {
      x[0].innerHTML = "Entre com seu numero ";
      y[0].innerHTML =
        "Ao tocar em Verificar, um SMS pode ser enviado. Podem aplicar-se taxas de mensagens e dados.";
      z[0].innerHTML = "Verificar";
      a[0].innerHTML = "Celular";
    }
  }, 1000);
};

export const checkAge = (birthDate) => {
  const ageDifMs = Date.now() - birthDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export function humanReadableTime(timeInMinutes) {
  var hours = Math.floor(timeInMinutes / 60);
  var minutes = timeInMinutes % 60;
  if (hours === 0) {
    return `${minutes} minuto(s)`;
  } else if (hours > 0 && minutes > 0) {
    return `${hours} horas e ${minutes} minuto(s)`;
  } else {
    return `${hours} horas`;
  }
}

export function humanReadableWeight(weightInKg) {
  if (weightInKg < 1) {
    return `${(weightInKg * 1000).toFixed(0)} g`;
  }
  return `${ToDecimal(weightInKg)} Kg`;
}

export function setupManifest() {
  const faviconIsBase64 = getDomainFavIcon().includes("base64");
  const manifestJson = {
    icons: [
      {
        src: faviconIsBase64
          ? getDomainFavIcon()
          : window.location.origin + getDomainFavIcon(),
        sizes: "144x144",
        type: "image/png",
        purpose: "any",
      },
    ],
    name: getDomainName(),
    start_url: window.location.origin,
    display: "standalone",
    theme_color: getDomainMarketColor(),
    background_color: "#ffffff",
  };

  const stringManifest = JSON.stringify(manifestJson);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector("#manifest").setAttribute("href", manifestURL);
}

export function setupMetaDescription() {
  getDomainMarketColor();
  document
    .querySelector("meta[name=\"description\"]")
    .setAttribute("content", getDomainMetaDescription());
}

export function setupKeywords() {
  getDomainMarketColor();
  document
    .querySelector("meta[name=\"keywords\"]")
    .setAttribute("content", getDomainKeywords());
}

export function timeZoneAdjustedMoment(date, format) {
  return moment(date, format).tz("America/Fortaleza");
}

export function translateCouponType(type) {
  switch (type) {
    case 0:
      return "Valor Absoluto";
    case 1:
      return "Porcentagem";
    case 2:
      return "Frete Grátis + possível valor";
    case 3:
      return "Frete Grátis + possível porcentagem";
    default:
      return "";
  }
}

export function formatarCEP(str) {
  var re = /^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}/;

  if (re.test(str)) {
    return str.replace(re, "$1.$2-$3");
  }

  return "";
}

export const isExpress = (method) => {
  return method.preparation_time ? true : false;
};

export const getExpressOperatingTime = (method) => {
  return timeZoneAdjustedMoment(new Date())
    .add(method.preparation_time || 0, "m")
    .toISOString();
};

export const generateOrderCreateParams = (
  app_generated_token,
  coupons,
  paymentSelectedType,
  method_delivery,
  market,
  delivery_time,
  cart,
  client,
  missing_items,
  custom_bags,
  cash,
  preferences,
  installment_number_of_parcels,
  club_member,
  higherValueLimit,
  acceptsHigherValueCapturing,
  selectedPostalData
) => {
  const player_id = 0;
  const missing_items_option_id = missing_items.options.find(
    (item) => item.description === missing_items.selected_option
  )?.id;

  const customer = {
    address_attributes: client.address_attributes,
    birthDate: client.birthDate,
    cpf: client.cpf,
    email: client.email,
    id: client.id,
    isCPFValid: client.isCPFValid,
    isEmailValid: client.isEmailValid,
    name: client.name,
    phone_number: client.phone_number,
    second_phone_number: client.second_phone_number,
    sex: client.sex,
    preferences: preferences,
    terms_of_use_version: client.terms_of_use_version,
    club_member: club_member,
    accepts_higher_value_capturing: acceptsHigherValueCapturing
  };
  return {
    cpf: localStorage.crmUserCpf,
    order: {
      customer_id: client.id,
      coupon_code: coupons.length > 0 ? coupons : undefined,
      originator: 2,
      cash: paymentSelectedType.id === 4 ? cash : undefined,
      delivery_type: method_delivery.mode,
      delivery_tax_calculation_method: method_delivery.tax_calculation_method,
      delivery_tax: selectedPostalData?.price ?? method_delivery?.tax ?? 0,
      delivery_method_id: method_delivery.id,
      service_tax: market.service_tax,
      deliver_start_time: isExpress(method_delivery)
        ? getExpressOperatingTime(method_delivery)
        : delivery_time.start_time,
      deliver_end_time: isExpress(method_delivery)
        ? getExpressOperatingTime(method_delivery)
        : delivery_time.end_time,
      payment_type_id: paymentSelectedType.id,
      express: isExpress(method_delivery),
      order_items_attributes: orderRequestFormattedItems(cart),
      client_attributes: customer,
      missing_items_option: missing_items.selected_option,
      missing_items_preferences: missing_items_option_id,
      custom_bag_value: custom_bags.custom_bags.custom_bag_value,
      friend_campaign_discount: 0,
      friend_campaign_id: null,
      installment_number_of_parcels: installment_number_of_parcels.number_of_parcels,
      installment_interest: installment_number_of_parcels.interest,
      player_id,
      app_generated_token,
      higher_value_limit: higherValueLimit,
      delivery_service_code: selectedPostalData?.code
    },
  };
};

export const generateOnlinePayParams = (
  market,
  deliveryMethod,
  client,
  order,
  paymentInfosEncrypted,
  paymentSelectedType
) => {
  const { complete_address } = market;
  const driveThru = deliveryMethod.deliveryMethod.mode === "drivethru";

  var address = {};
  if (complete_address && driveThru) {
    address = {
      ...complete_address,
      reference: complete_address.reference_spot,
    };
  } else {
    const { address_attributes } = client;
    address = {
      ...client.address_attributes,
      reference: address_attributes.reference_spot,
    };
  }

  const pubKey = process.env.REACT_APP_MPAY_PUB_KEY;
  const paymentDict = {
    brand: paymentInfosEncrypted.brand,
    card_expiration_date: paymentInfosEncrypted.card_expiration_date,
    card_holder_name: paymentInfosEncrypted.card_holder_name,
    card_number: paymentInfosEncrypted.card_number,
    client_name: paymentInfosEncrypted.client_name,
    cvv: paymentInfosEncrypted.cvv,
    order_number: getEncryptedValue(pubKey, order.order_number),
    value_to_authorize: getEncryptedValue(pubKey, order.total_price),
    card_id: paymentInfosEncrypted.card_id
  };

  return {
    market_id: market.id,
    company: paymentSelectedType.codename,
    test_mode: TEST_MODE,
    order_id: order.id,
    payment: paymentDict,
    address: address,
    should_include_on_clearsale: true,
  };
};

export const orderValid = (method_delivery, delivery_time = {}, client = {}, paymentMethod, cash, total, selectedPostalData) => {

  const { phone_number, name, cpf, responsible_document, birthDate, address_attributes, email } = client;
  const cpfData = cpf.length > 11 ? responsible_document : cpf

  const deliveryTimeValid = delivery_time.enabled === true && delivery_time.start_time && delivery_time.end_time;
  const paymentValid = paymentMethod?.id === 4 ? (cash > total || !cash) : !!paymentMethod?.id;
  const deliveryValid = isExpress(method_delivery) ? method_delivery?.id : delivery_time !== undefined;
  const addressValid = (address_attributes?.reference_spot && address_attributes?.number) || method_delivery?.mode === "drivethru" || false;
  const clientValid = phone_number && email && name && validate(cpfData) && birthDate !== "Invalid date";
  let error = {
    message: "",
    position: null,
  };
  if (!deliveryValid) {
    error = {
      message:
        "Para finalizar pedido é necessário preencher método/horário de entrega.",
      position: 1,
    };
  } else if (!clientValid) {
    error = {
      message:
        "Para finalizar pedido é necessário preencher todos os dados do cliente corretamente.",
      position: 2,
    };
  } else if (!paymentValid) {
    error = {
      message:
        "Para finalizar pedido é necessário preencher todos os dados de pagamento corretamente.",
      position: 3,
    };
  } else if (!addressValid) {
    error = {
      message:
        "Para finalizar pedido é necessário preencher todos os dados de endereço corretamente.",
      position: 4,
    };
  } if(method_delivery.tax_calculation_method === 'correios'){
    if(!selectedPostalData){
      error = {
        message: 
          "Tipo de envio não selecionado.",
        position: 5
      }
    }
  }else{
     if(!deliveryTimeValid) {
    error = {
      message:
        "O horário selecionado não está mais disponível, selecione uma nova opção de horário.",
      position: 5,
    };
  }}

  return error.position ? error : null;
}

export function groupBySection(products, categories) {
  const highlightProducts = {
    id: -1,
    display_name: "Destaques",
    products: products.filter((product) => product.is_highlight)
  }
  return [highlightProducts, ...categories.map((category) => {
    return {
      ...category,
      products: products.filter((product) => Number(product.section_id) === category.id && !product.is_highlight)
    }
  })
  ].filter((category) => category.products.length > 0)
}

export function getCartItemPreferences(itemCart){
  const {mix_item_preference, customer_preferences} = itemCart
  if (!mix_item_preference && !customer_preferences) {
    return itemCart.item_preference;
  }
  const preferencesArray = [mix_item_preference, customer_preferences]
  const nonEmptyPreferences = preferencesArray.filter(pref => pref !== undefined && pref !== null && pref !== '').join(" - ");
  
  return nonEmptyPreferences;
}

export const changeDocumentTitle = (product = {}) => {
  const onProductPage = window.location.pathname.includes("produto")
  const domainName = getDomainName()
  if (onProductPage) {
    document.title = product.description
  } else {
    if (document.title !== domainName) {
      document.title = domainName;
    }
  }
}


export const validateCpf = (value) => {
  let error;
  if (!validate(value)) {
    error = "Insira um cpf válido";
  }
  return error;
};

export function validateCNPJ(value) {
  function validate(cnpj) {
    cnpj = cnpj?.replace(/[^\d]+/g, '');
    
    if (cnpj.length !== 14) {
      return false;
    }
    
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
      ) {
        return false;
      }
      
      var sum = 0;
      var weight = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      
      for (var i = 0; i < 12; i++) {
        sum += parseInt(cnpj[i]) * weight[i];
      }
      
      var remainder = (sum % 11);
      
      if (remainder < 2) {
      if (parseInt(cnpj[12]) !== 0) {
        return false;
      }
    } else {
      if (parseInt(cnpj[12]) !== 11 - remainder) {
        return false;
      }
    }
    
    sum = 0;
    weight = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    
    for (var i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * weight[i];
    }
    
    remainder = (sum % 11);
    
    if (remainder < 2) {
      if (parseInt(cnpj[13]) !== 0) {
        return false;
      }
    } else {
      if (parseInt(cnpj[13]) !== 11 - remainder) {
        return false;
      }
    }
    
    return true;}
    let error;
    if (!validate(value)) {
      error = "Insira um cnpj válido";
    }
    return error;
  }

export const displayAmountInKilo = (amount, amountKilo) => {
  const isInKilogram = (amount*amountKilo) >= 1
  const weightUnit = isInKilogram ? "Kg" : "g"
  const weightInKilo = parseFloat((amount*amountKilo).toFixed(3))
  const currentWeight = isInKilogram ? weightInKilo :  weightInKilo * 1000
  return `${String(currentWeight).replaceAll(".", ",")} ${weightUnit}`
}

export const calculateBusinessDays = (startDate, endDate) => {
  let count = 0;
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 = Sunday, 6 = Saturday
      count++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return count;
};

export const arrayHasContent = (arr) => {
  return Array.isArray(arr) && arr.length > 0
}

export const formatDeadlineToDateString = (deadline) => {
  const date = new Date(deadline);
  const isoString = date.toISOString();
  const datePart = isoString.split('T')[0];
  const timePart = isoString.split('T')[1].slice(0, -1);
  return `${datePart}T${timePart}-03:00`;
}

export const isUserUnderAge = (user) => {
  if (!user) return false;
  
  const birthDate = user.birth_date || user.birthDate;

  if (!birthDate) return false;

  const [day, month, year] = birthDate.split('/');
  const birthDateObj = new Date(`${year}-${month}-${day}`);

  const today = new Date();

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDifference = today.getMonth() - birthDateObj.getMonth();
  const dayDifference = today.getDate() - birthDateObj.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age < 18;
}


